import {useState} from "react";
import {VideoOverviewModel} from "./models";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Link,
    Typography
} from "@mui/material";
import { OverviewElement } from "./overviewElement";
import {VideoComponent} from "./videoComponent";

export const Overview = () => {
    const [video, setVideo] = useState<VideoOverviewModel>();
    return (
        <>
            <div id="top"></div>
            <Grid container>
                {
                    video ? <VideoComponent video={video} />: null
                }
                <Box sx={{ width:'100%', overflow: 'auto'}}>
                    <OverviewElement setVideo={setVideo} videoPresent={ video ? true: false }/>
                </Box>
            </Grid>
        </>
    )// When the user clicks on the button, scroll to the top of the document
}

