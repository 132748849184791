import {
    Container,
    Dialog,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {VideoOfferModel} from "./models";

interface RequestVideoComponentProps
{
    open: boolean;
    handleClose: () => void;
    videoOffers: VideoOfferModel[];
}

export const RequestVideoComponent = (props: RequestVideoComponentProps) => {
    return (
        <Dialog open={props.open} onClose={props.handleClose} >
            <Container sx={{ pb: '20px'}}>
                <h2>Nieuwe video's kopen?</h2>
                <p>Stuur een mailtje naar info@musicalinasse.be</p>
                <h4>Beschikbare video's:</h4>
                <TableComponent videoOffers={props.videoOffers} />
            </Container>
        </Dialog>
    )
}

interface TableComponentProps
{
    videoOffers: VideoOfferModel[];
}

const TableComponent = (props: TableComponentProps) => {
    return (
        <TableContainer sx={{ pb: '10px' }} component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {props.videoOffers.map((row) => (
                        <TableRow
                            key={row.name}material-ui
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell ><img src={row.imageUrl}/></TableCell>
                            <TableCell align="left">
                                <Typography noWrap variant="body1">
                                    {row.name}
                                </Typography>
                                </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}