const keycloakTokenStorageKey = "keycloak_token";

export interface KeycloakTokenResult {
    idToken?: string,
    token?: string,
    refreshToken?: string
}

export function GetKeycloakRequestHeaders():Record<string,string>
{
    return GetRequestHeaders(keycloakTokenStorageKey);
}

export function GetRequestHeaders(storageKey: string):Record<string,string>
{
    return {
        "Authorization": `Bearer ${localStorage[storageKey]}`,
        "Content-type": "application/json"
    }
}

export function CacheKeycloakTokens(token: KeycloakTokenResult) {
    localStorage[keycloakTokenStorageKey] = token.token;
}