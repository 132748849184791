import {AppBar, Box, Button, styled, Toolbar, Typography} from "@mui/material";
import keycloak from "../auth/keycloak";
import React from "react";

const CustomNavbar = styled(AppBar)(({theme }) => ({
    background: theme.status.danger
}));


export const Navbar = () => {
    return(
        <CustomNavbar
            position="fixed"
            color="default"
            elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Box
                        component="img"
                        sx={{
                            height: 60,
                        }}
                        alt="Your logo."
                        src="./logo-musicalia.png"
                    />
                    <Box
                        sx={{
                            flexGrow: 1
                        }}
                    />
                    <Button sx = {{ color: 'white' }} onClick={() => keycloak.logout()} >
                        Uitloggen
                    </Button>
            </Toolbar>
        </CustomNavbar>
    );
}