import React from 'react';
import './App.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from "./features/auth/keycloak";
import {CacheKeycloakTokens} from "./helpers/tokenHelpers";
import {Overview} from "./features/videos/overview";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {
    AppBar, Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    GlobalStyles, Grid,
    Link, Paper, Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {pink} from "@mui/material/colors";
import {Navbar} from "./features/nav/navbar";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme test`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}



const App = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'sans-serif'
            ].join(','),
        },
        status: {
            danger:'#ff1053',
        },
    });
    return (
        <>
          <ReactKeycloakProvider authClient={keycloak} LoadingComponent ={Loading()} onTokens={CacheKeycloakTokens} initOptions={{ onLoad: "login-required"}}>
             <ThemeProvider theme={theme}>
                 <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                 <CssBaseline />
                 <Box height="100vh" display={"flex"} >
                 <Navbar />
                    <Box sx={{overflow: 'auto', width: "100%"}} >
                        <Overview />
                    </Box>
                 </Box>
             </ThemeProvider>
          </ReactKeycloakProvider>
        </>
    )
}

const Loading = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >

            <Grid item xs={3}>
                <CircularProgress />
                Laden...
            </Grid>

        </Grid>
    );
}

export default App;