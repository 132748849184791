import {VideoOverviewModel} from "./models";
import {Container} from "@mui/material";

interface VideoComponentProps {
    video: VideoOverviewModel;
}
export const VideoComponent = (props: VideoComponentProps) => {
    return (
        <>
            <Container sx={{ py: 8, mt: 4 }}>
                <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
                    <iframe
                        src={props.video.playerEmbedUrl}
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                        style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}}>
                    </iframe>
                </div>
                <h2>Nu aan het afspelen: {props.video.name}</h2>
            </Container>
        </>
    )
}