import {VideoOfferModel, VideoOverviewModel} from "./models";
import {useEffect, useState} from "react";
import {fetchVideoOffers, fetchVideos} from "./apiCalls";
import {
    Alert,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    Grid,
    IconButton,
    Typography
} from "@mui/material";

import  { AccessAlarm, Info } from "@mui/icons-material";
import {RequestVideoComponent} from "./requestVideoComponent";

interface OverviewElementProps
{
    setVideo: (model: VideoOverviewModel) => void;
    videoPresent: boolean;
}

export const OverviewElement = (props: OverviewElementProps) => {
    const [videos, setVideos] = useState<VideoOverviewModel[]>([]);
    const [videoOffers, setVideoOffers] = useState<VideoOfferModel[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const loadVideoOverview = () => {
        fetchVideos().then(result => {
            const videos = result.data;
            if(videos && videos.length > 0)
            {
                props.setVideo(videos[0]);
            }
            setVideos(videos);
        });
        fetchVideoOffers().then(result => {
            setVideoOffers(result.data);
        });
    }

    useEffect(() => {
        loadVideoOverview();
    }, []);

    function goToTop() {
        const anchor = document.querySelector(
            "#top"
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    return (
        <>
            <Container>
                {
                    videos && videos.length > 0 ?
                            <h2>Beschikbare video's
                                <IconButton color="primary"  size="small" sx={{ mb: 2 }} onClick={() => setOpen(true)}>
                                    <Info />
                                </IconButton>
                            </h2>: undefined
                }
                {
                    videos && videos.length > 0 ?

                        <Grid container spacing={2}>
                            {videos.map((card) => (
                                <Grid item key={card.name}>
                                    <CardActionArea onClick={() => {
                                        goToTop();
                                        props.setVideo(card);
                                    }}>
                                        <Card sx={{ width: '300px'}}>
                                            <CardMedia
                                                component="img"
                                                image={ card.pictures.sizes[2].link }
                                                alt= { card.name }
                                            />
                                            <CardContent>
                                                <Typography noWrap variant="body2">
                                                    { card.name }
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </CardActionArea>
                                </Grid>))}
                        </Grid>:
                        <Alert sx={{ mt: '80px' }} severity="warning"><Typography variant="body1">Nog geen video's beschikbaar.<Button sx={{ pt: '0', pb: '0'}} onClick={() => setOpen(true)}>Video's toevoegen aan bibliotheek</Button></Typography></Alert>
                }
            </Container>
            <RequestVideoComponent open={open} videoOffers={videoOffers} handleClose={() => setOpen(false)}/>
        </>
    );

}
