import axios, {AxiosResponse} from "axios";
import {VideoOfferModel, VideoOverviewModel} from "./models";
import {GetKeycloakRequestHeaders} from "../../helpers/tokenHelpers";

export const fetchVideos = ():Promise<AxiosResponse<VideoOverviewModel[]>> =>  {
    return axios.get<VideoOverviewModel[]>(`${process.env.REACT_APP_VIDEO_SYNC_BASE_ADDRESS}/client/videos`, { headers: GetKeycloakRequestHeaders() })
}
export const fetchVideoOffers = ():Promise<AxiosResponse<VideoOfferModel[]>> =>  {
    return axios.get<VideoOfferModel[]>(`${process.env.REACT_APP_VIDEO_SYNC_BASE_ADDRESS}/client/videoOffers`, { headers: GetKeycloakRequestHeaders() })
}
